import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?lang=cz&paged=15
// http://www.dktech.cz/?p=122

const PostVanocniC4: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_budoucnost"
      nextLink="/post_stehovani_odstavka"
    >
      <PostHeader
        title="Vánoční C4"
        link="/post_vanocni_c4"
        author="Rah"
        date="24.12.2013"
        time="23:09"
        category="Oznámení"
      />
      <p>Jelikož stále ještě neběží Gracia Final, tak jsme se domluvili a prozatím, dokud nepoběží GF jsme zprovoznili C4. Server běží se stejným nastavením i rates jako tenkrát, databáze je čistá, začíná se od nuly. Kdo má zájem a chce zavzpomínat má tu možnost.</p>
      <p>Klient:&nbsp;<a href="http://www.fileplanet.com/160491/download/Lineage-II-Chronicle-4:-Scions-of-Destiny---Game-Client" target="_blank">http://www.fileplanet.com/160491/download/Lineage-II-Chronicle-4:-Scions-of-Destiny—Game-Client</a><br />
        System:&nbsp;<a title="https://dktech.cz/links/patch.php" href="https://dktech.cz/links/patch.php" target="_blank">https://dktech.cz/links/patch.php</a><br />
        Registrace: <a title="https://dktech.cz/links/registration.php" href="https://dktech.cz/links/registration.php" target="_blank">https://dktech.cz/links/registration.php</a></p>
      <p>Diskuze k tématu:&nbsp;<a href="https://www.dktech.cz/forum/index.php/topic,29.0.html" target="_blank">https://www.dktech.cz/forum/index.php/topic,29.0.html</a></p>
      <p>Za tým DKTech přeji krásné prožití svátků vánočních a příjemnou hru Rah</p>


      <div className="cleared">&nbsp;</div>
    </PageTemplate>
  )
}

export default PostVanocniC4

export { Head } from '../components/defaultHtmlHead'